<mat-sidenav-container class="softobiz-sidenav admin-sidenav" autosize [class.side-nav-open]="isActive">
  <mat-sidenav #sidenav mode="side" #drawer (openedChange)="onDrawerChange($event)">
    <div class="border-2 side-nav-bar">
      <div class="side-nav-logo" *ngIf="!sidebarOpen">
        <img src="/images/logo.png" alt="profile" />
      </div>
      <div class="collapse-side-nav-logo" *ngIf="sidebarOpen">
        <img src="/images/collapse-logo.png" />
      </div>
      <mat-list role="list" class="w-full">
        <ng-container *ngIf="isModuleAllowed('dashboard')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item1'" (click)="selectItem('item1')"
            *ngIf="hasModule('dashboard')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/space_dashboard.svg" />
              <span>Dashboard</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('account')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item2'" (click)="selectItem('item2')"
            *ngIf="hasModule('account')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/contacts.svg" />
              <span>Account</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('cost_center')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item3'" (click)="selectItem('item3')"
            *ngIf="hasModule('cost_center')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/business_center.svg" />
              <span>Cost Center</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('project')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item4'" (click)="selectItem('item4')"
            *ngIf="hasModule('project')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/source_environment.svg" />
              <span>Projects</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('resource')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item5'" (click)="selectItem('item5')"
            *ngIf="hasModule('resource')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/crowdsource-admin.svg" />
              <span>Resource</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('open_position')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item6'" (click)="selectItem('item6')"
            *ngIf="hasModule('open_position')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/chair_alt.svg" />
              <span>Open Positions</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('invoice')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item7'" (click)="selectItem('item7')"
            *ngIf="hasModule('invoice')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/contract.svg" />
              <span>Invoicing</span>
            </div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="isModuleAllowed('user_management')">
          <mat-list-item role="listitem" [class.active-list]="selectedItemId === 'item8'" (click)="selectItem('item8')"
            *ngIf="hasModule('user_management')">
            <div class="sidebar-menu cursor-pointer">
              <img src="/images/icons/manage_accounts.svg" />
              <span>User Management</span>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
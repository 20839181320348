import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  selectedItemId: string | null = null;
  @Input() sidebarOpen: boolean = false;
  isDrawerOpen = false;
  isActive = false;
  modules: any[] = [];
  allowedModules: string[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      this.modules = JSON.parse(permissions);
    }
    // Get initial route
    this.route.url
      .pipe(map((segments) => segments.join('/')))
      .subscribe((url) => {
        this.setSelectedItem(url);
      });

    // Listen for navigation events
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url.split(/[?#]/)[0])
      )
      .subscribe((url) => {
        this.setSelectedItem(url);
      });
  }

  ngOnInit(): void {
    const modules = localStorage.getItem('modules');
    if (modules) {
      this.modules = JSON.parse(modules);
    }
    this.filterAllowedModules();
  }

  filterAllowedModules(): void {
    this.allowedModules = this.modules
      .filter((module) => module.permission.includes('view'))
      .map((module) => module.module);
  }

  isModuleAllowed(moduleName: string): boolean {
    return this.allowedModules.includes(moduleName);
  }

  setSelectedItem(url: string): void {
    if (url === '/dashboard') {
      this.selectedItemId = 'item1';
    } else if (url.startsWith('/account')) {
      this.selectedItemId = 'item2';
    } else if (url.startsWith('/cost-center')) {
      this.selectedItemId = 'item3';
    } else if (url.startsWith('/projects')) {
      this.selectedItemId = 'item4';
    } else if (url.startsWith('/resource')) {
      this.selectedItemId = 'item5';
    } else if (url.startsWith('/open-positions')) {
      this.selectedItemId = 'item6';
    } else if (url.startsWith('/invoicing')) {
      this.selectedItemId = 'item7';
    } else if (url.startsWith('/user-management')) {
      this.selectedItemId = 'item8';
    } else if (url.startsWith('/permission/manage')) {
      this.selectedItemId = 'item8';
    } else {
      this.selectedItemId = 'null';
    }
  }

  toggleClass(): void {
    this.isActive = !this.isActive;
  }

  selectItem(itemId: string): void {
    this.selectedItemId = itemId;
    switch (itemId) {
      case 'item1':
        this.router.navigate(['/dashboard']);
        break;
      case 'item2':
        this.router.navigate(['/account']);
        break;
      case 'item3':
        this.router.navigate(['/cost-center']);
        break;
      case 'item4':
        this.router.navigate(['/projects']);
        break;
      case 'item5':
        this.router.navigate(['/resource']);
        break;
      case 'item6':
        this.router.navigate(['/open-positions']);
        break;
      case 'item7':
        this.router.navigate(['/invoicing']);
        break;
      case 'item8':
        this.router.navigate(['/user-management']);
        break;
      default:
        break;
    }
  }

  onDrawerChange(opened: boolean): void {
    this.isDrawerOpen = opened;
  }

  // Function to check if module exists in user permissions
  hasModule(moduleName: string): boolean {
    const module = this.modules.find(
      (p) => p.module.toLowerCase() === moduleName.toLowerCase()
    );
    if (!module) {
      return true;
    }
    return !!module?.permission.length;
  }
}

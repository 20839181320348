import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../../../../../shared/modules/angular-material/angular-material.module';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [FormsModule, AngularMaterialModule, RouterModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
